import * as actionTypes from './actionTypes'
import api from '../../api'
import * as APIConstants from '../APIConstants'
import config from '../../assets/config.json'

export const GetSiteConfig = () => {
    return dispatch => {
        var body = {
            tenantId: config.name
        }

        api.post(APIConstants.fetchSiteConfig, body).then(response => {
            if (response.status === 200) {
                const siteConfig = response?.data
                let socialLinks
                let appBadgesLinks
                let unfilteredcustomLinks
                siteConfig.forEach(ele => {
                    switch (ele.configurationType) {
                        case 1:
                            socialLinks = ele
                            break;
                        case 2:
                            appBadgesLinks = ele
                            break;
                        case 3:
                            unfilteredcustomLinks = ele
                            break;

                        default:
                            break;
                    }

                });
                const customLinks = unfilteredcustomLinks.data.filter((obj) => obj["status"] === true)
                dispatch({
                    type: actionTypes.FETCH_SITE_CONFIG_SUCCESS,
                    socialLinks: socialLinks,
                    appBadgesLinks: appBadgesLinks,
                    customLinks: customLinks,
                });
            }
            else {
                dispatch({
                    type: actionTypes.FETCH_SITE_CONFIG_FAIL,
                    socialLinks: null,
                    appBadgesLinks: null,
                    customLinks: null,
                });
            }
        });
    };
};